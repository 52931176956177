<template>
    <Layout>
        <!-- <PageHeader :title="title"/> -->
        <div class="d-flex justify-content-between align-items-center">
            <h3 class="mb-0 font-size-18 fw-bold">
                {{ $t(`ip blocking`) }}
            </h3>
            <button
                :class="`btn btn-primary ${loading ? 'disabled' : ''}`"
                @click="goToForm()">
                {{ $t('block ip') }}
            </button>
        </div>

        <div class="card data">
            <div class="card-header bg-light bg-soft filters">
                <b-form inline>
                    <table class="table table-bordered table-nowrap mb-0">
                        <tbody>
                            <tr>
                                <td class="text-center">
                                    {{ $t('search') }}
                                </td>
                                <td lass="bg-white">
                                    <div class="d-flex gap-1 w-100">
                                        <datepicker
                                            v-model="filters.from"
                                            :first-day-of-week="0"
                                            :locale="$i18n.locale=='ko'?koLocale:null"
                                            confirm
                                            class="form-control bg-white"></datepicker>

                                        <label class="input-group-text"
                                            >~</label
                                        >
                                        <datepicker
                                            v-model="filters.to"
                                            :first-day-of-week="0"
                                            :locale="$i18n.locale=='ko'?koLocale:null"
                                            confirm
                                            class="form-control bg-white"></datepicker>
                                        <button
                                            class="btn btn-outline-info"
                                            @click="setDay('today')">
                                            {{ $t('today') }}
                                        </button>
                                        <button
                                            class="btn btn-outline-success"
                                            @click="setDay('yesterday')">
                                            {{ $t('yesterday') }}
                                        </button>
                                    </div>
                                </td>
                                <td class="text-center">
                                    {{ $t('search') }}
                                </td>
                                <td class="bg-white">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`enter keyword`)"
                                        v-model="filters.search" />
                                </td>
                                <td>
                                    <button
                                        :class="`btn btn-danger ${
                                            loading ? 'disabled' : ''
                                        }`"
                                        @click="resetFilter()">
                                        {{ $t('reset') }}
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </b-form>
            </div>
            <div class="card-body">
                <div ref="tableScroller" class="table-responsive">
                    <LoadingTable
                        :cols="6"
                        :rows="50"
                        :td_small_class="`d-none`"
                        class="table-bordered table-striped"
                        v-if="loading" />
                    <table
                        v-else
                        class="table table-bordered table-hover table-striped table-nowrap mb-0">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">
                                    {{ $t('ip address') }}
                                </th>
                                <th scope="col">
                                    {{ $t('username') }}
                                </th>
                                <th scope="col">
                                    {{ $t('note') }}
                                </th>
                                <th scope="col">
                                    {{ $t('place') }}
                                </th>
                                <th scope="col">
                                    {{ $t('created by') }}
                                </th>
                                <th scope="col">
                                    {{ $t('management') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(r, i) in data.list" :key="r._id">
                                <td class="text-center">
                                    {{
                                        i +
                                        1 +
                                        (data.current_page - 1) * data.per_page
                                    }}
                                </td>
                                <td class="text-center">
                                    <a
                                        :title="$t(`click to view`)"
                                        @click="goToForm(r._id)"
                                        href="javascript:void(0)"
                                        class="text-primary d-flex justify-content-center align-items-center"
                                        ><i class="mdi mdi-ip fs-5"></i>
                                        <span>{{ r.ip }}</span></a
                                    >
                                </td>
                                <td class="text-center">
                                    {{ numberString(r.connected_users) }}
                                </td>
                                <td class="">
                                    <div
                                        :id="`hover-div-${r._id}`"
                                        v-html="r.note.trim()"
                                        class="html-notes"
                                        :style="{
                                            maxHeight: '18px',
                                            overflow: 'hidden',
                                        }"
                                        @mouseover="showHoverDiv(r._id)"
                                        @mouseout="hideHoverDiv(r._id)"></div>
                                </td>
                                <td class="text-center">
                                    {{ dateYmD(r.created_at, true) }}
                                </td>
                                <td class="text-center">
                                    {{ r.created_by.username }}
                                </td>
                                <td class="text-center">
                                    <div class="button-items">
                                        <button
                                            class="btn btn-outline-danger btn-sm"
                                            @click="remove(r)">
                                            {{ $t('delete') }}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        class="no-data-placeholder"
                        v-if="!data.list.length && !loading">
                        {{ $t('no data found') }}
                    </div>
                </div>
                <Pagination :data="data" @emitPage="initList" />
            </div>
        </div>
        <b-modal
            v-model="create_modal"
            centered
            hide-footer
            :title="`${$t(`block IP`)}`"
            @hidden="reset">
            <b-form @submit.prevent="submitForm">
                <b-form-group class="mb-3" :label="$t('ip address')">
                    <input
                        v-model="pl.ip"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': submitted && v$.pl.ip.$error,
                        }" />
                    <div
                        v-for="(item, index) in v$.pl.ip.$errors"
                        :key="index"
                        class="invalid-feedback">
                        <span v-if="item.$message">{{ item }}</span>
                    </div>
                </b-form-group>
                <b-form-group class="mb-3" :label="$t('note')">
                    <textarea
                        v-model="pl.note"
                        class="form-control"
                        style="resize: none"
                        :class="{
                            'is-invalid': submitted && v$.pl.note.$error,
                        }"></textarea>
                    <div
                        v-for="(item, index) in v$.pl.note.$errors"
                        :key="index"
                        class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                </b-form-group>
                <div
                    class="table-responsive mb-3 border"
                    style="max-height: 200px; min-height: 100px"
                    v-if="viewMode">
                    <table
                        class="table table-bordered table-hover table-striped table-sm">
                        <thead>
                            <tr
                                class="bg-white"
                                style="position: sticky; top: -1px; z-index: 2">
                                <th colspan="2">
                                    <i class="bx bxs-user me-1"></i
                                    >{{ $t('connected users') }}
                                    <span class="text-info ms-3">{{
                                        pl.connected_users.length
                                    }}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(c, i) in pl.connected_users"
                                :key="c._id">
                                <td class="text-center">
                                    {{ i + 1 }}
                                </td>
                                <td>
                                    <span
                                        class="badge"
                                        :class="{
                                            'bg-warning': c.role == 'admin',
                                            'bg-success': c.role == 'agent',
                                            'bg-primary': c.role == 'player',
                                        }"
                                        >{{ c.role }}</span
                                    >
                                    {{ c.username }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <button class="btn btn-warning float-end">
                    {{ $t('submit') }}
                </button>
            </b-form>
        </b-modal>
    </Layout>
</template>

<script>
import Datepicker from 'vue3-datepicker';
import Layout from '../../../layouts/main';
import PageHeader from '@/components/page-header';
import LoadingTable from '@/components/loading-table';
import Pagination from '../../../../components/pagination.vue';
import Multiselect from '@vueform/multiselect';
import Switches from 'vue-switches';
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';
import convert from '../../../../mixins/convert';
import datetime from '../../../../mixins/datetime';
import number from '../../../../mixins/number';
import ui from '../../../../mixins/ui';
import string from '../../../../mixins/string';
import { required, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

const validIP = (value) => {
    const regex = /\b(?:\d{1,3}\.){3}\d{1,3}\b/;
    return helpers.req(value) && regex.test(value);
};
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        Layout,
        PageHeader,
        Pagination,
        Switches,
        LoadingTable,
        Multiselect,
        Datepicker,
    },
    mixins: [datetime, number, convert, ui, string],
    data() {
        return {
            test: null,
            title: `players`,
            filters: {
                keyword: null,
                status: null,
                from: new Date(this.firstDateOftheMonth()),
                to: new Date(),
            },
            data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            create_modal: false,
            loading: false,
            hoverDivLeft: 0,
            hoverDivTop: 0,
            pl: {
                _id: null,
                ip: null,
                note: null,
                connected_users: [],
            },
            pl_origin: {},
        };
    },
    validations: {
        pl: {
            ip: {
                required: helpers.withMessage('required', required),
                validIP: helpers.withMessage(
                    'must be a valid ip address',
                    validIP
                ),
            },
            note: {
                required: helpers.withMessage('required', required),
            },
        },
    },
    watch: {
        filters: {
            handler() {
                this.initList(1);
            },
            deep: true,
        },
        '$store.state.key'() {
            this.initList(1);
        },
    },
    computed: {
        viewMode() {
            return this.pl._id != null;
        },
        forms() {
            var m_fd = this.formDataToObject(this.objectToFormData(this.pl));
            var o_fd = this.formDataToObject(
                this.objectToFormData(this.pl_origin)
            );
            var f_fd = new FormData();
            var rows = '';
            for (var key in m_fd) {
                if (m_fd.hasOwnProperty(key)) {
                    var m_value =
                        m_fd[key] == 'true'
                            ? '1'
                            : m_fd[key] == 'false'
                            ? '0'
                            : m_fd[key];
                    var o_value =
                        o_fd[key] == 'true'
                            ? '1'
                            : o_fd[key] == 'false'
                            ? '0'
                            : o_fd[key];
                    var bool_values = [
                        ['inactive', 'active'],
                        ['disabled', 'enabled'],
                    ];
                    var bool_value =
                        key == 'status' ? bool_values[0] : bool_values[1];
                    if (!this.viewMode) {
                        // CREATE MODE
                        if (m_fd[key] != null) {
                            f_fd.append(key, m_value);
                        }
                    } else {
                        // EDIT MODE
                        if (
                            m_fd[key] != o_fd[key] &&
                            !['agent[commissions][enabled]'].includes(key)
                        ) {
                            f_fd.append(key, m_value);

                            rows += `<tr>
                                    <td>${this.arrayToArrows(key)}</td>
                                    <td class="${
                                        o_fd[key] == 'true'
                                            ? 'text-primary'
                                            : o_fd[key] == 'false'
                                            ? 'text-secondary'
                                            : ''
                                    }">
                                        ${
                                            o_fd[key] == 'true'
                                                ? bool_value[1]
                                                : o_fd[key] == 'false'
                                                ? bool_value[0]
                                                : o_fd[key]
                                        }
                                    </td>
                                    <td class="${
                                        m_fd[key] == 'true'
                                            ? 'text-primary'
                                            : m_fd[key] == 'false'
                                            ? 'text-secondary'
                                            : ''
                                    }">
                                        ${
                                            m_fd[key] == 'true'
                                                ? bool_value[1]
                                                : m_fd[key] == 'false'
                                                ? bool_value[0]
                                                : m_fd[key]
                                        }
                                    </td>
                                </tr>`;
                        }
                    }
                }
            }

            return {
                obj_array: this.formDataToObject(f_fd),
                form_data: f_fd,
                x_www_form_urlencoded: this.formDataToUrlEncoded(f_fd),
                changes: rows,
                original: o_fd,
                modified: m_fd,
            };
        },
    },
    methods: {
        ...mapActions('ipBlock', {
            ipBlockGetList: 'getList',
            ipBlockCreate: 'create',
            ipBlockUpdate: 'update',
            ipBlockDelete: 'delete',
            ipBlockView: 'view',
        }),
        async initList(p) {
            this.resetScroll();
            var pl = {
                status: this.filters.status,
                from: this.dateYmD(this.filters.from) + ' 00:00:00',
                to: this.dateYmD(this.filters.to) + ' 23:59:59',
                page_no: p ? p : 1,
                search: this.filters.search ? this.filters.search : '',
            };

            this.loading = true;
            const data = await this.ipBlockGetList(pl);
            this.data.list = data.data;
            this.data.links = data.links;
            this.data.current_page = data.current_page;
            this.data.per_page = data.per_page;
            this.data.last_page = data.last_page;
            this.data.total = data.total;
            this.loading = false;
        },
        async submitForm() {
            this.submitted = true;
            this.v$.$touch();
            if (this.v$.$invalid) {
                return;
            } else {
                if (this.viewMode && !this.forms.changes) {
                    Swal.fire({
                        icon: 'info',
                        title: this.$t('no changes made'),
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    return;
                }
                var fd = new FormData();

                fd.append('ip', this.pl.ip);
                fd.append('note', this.pl.note);
                const res = !this.viewMode
                    ? await this.ipBlockCreate({ form_data: fd })
                    : await this.ipBlockUpdate({
                          form_data: this.formDataToUrlEncoded(fd),
                          id: this.pl._id,
                      });

                var title = {
                    create: res.success
                        ? this.$t('blocked ip has been created')
                        : this.$t('creating failed'),
                    update: res.success
                        ? this.$t('blocked ip has been updated')
                        : this.$t('updating failed'),
                };

                if (res) {
                    Swal.fire({
                        title: this.viewMode ? title.update : title.create,
                        icon: res.success ? 'success' : 'error',
                        timer: 2000,
                    });
                    if (res.success) {
                        this.reset();
                        this.initList();
                    }
                }
            }
        },
        async showUpdateModal(r) {
            const data = await this.ipBlockView(r._id);
            this.pl._id = data._id;
            this.pl.ip = data.ip;
            this.pl.note = data.note;
            this.pl.connected_users = data.connected_users;
            this.create_modal = true;

            this.pl_origin = JSON.parse(JSON.stringify(data));
        },
        async remove(r) {
            if (!this.isAuthorized()) return;
            const confirmed = await Swal.fire({
                title: `${this.$t('are you sure')}?`,
                html: `${this.$t('delete this ip address')}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f1b44c',
                cancelButtonColor: '#74788d',
                cancelButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('confirm'),
            }).then((result) => {
                return result.value;
            });

            if (confirmed) {
                const res = await this.ipBlockDelete({ id: r._id });

                if (res) {
                    Swal.fire({
                        icon: res.success ? 'success' : 'error',
                        title: res.success
                            ? this.$t('ip address has been deleted')
                            : this.$t('deleting failed'),
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    if (res.success) {
                        this.initList(1);
                    }
                }
            }
        },
        resetFilter() {
            this.filters.search = '';
            this.filters.from = new Date(this.firstDateOftheMonth());
            this.filters.to = new Date();
        },
        reset() {
            this.submitted = false;
            this.create_modal = false;
            this.pl._id = null;
            this.pl.ip = null;
            this.pl.note = null;
        },
        showHoverDiv(id) {
            const hoverDiv = document.getElementById(`hover-div-${id}`);
            const children = document.querySelectorAll(`#hover-div-${id} *`);
            if (!hoverDiv) return;
            hoverDiv.style.maxHeight = '';
            children.forEach((child) => {
                child.style.maxWidth = 'unset';
            });
        },
        hideHoverDiv(id) {
            const hoverDiv = document.getElementById(`hover-div-${id}`);
            const children = document.querySelectorAll(`#hover-div-${id} *`);
            if (!hoverDiv) return;
            hoverDiv.style.maxHeight = '18px';
            children.forEach((child) => {
                child.style.maxWidth = '200px';
            });
        },
        goToForm(id) {
            var w = window.open(
                `/player/ip-block/form${id ? '?id=' + id : ''}`,
                'ip-block-form',
                'width=' + 1020 + ',height=' + (screen.height - 200)
            );
        },
        setDay(mode) {
            if (mode == 'today') {
                this.filters.from = new Date();
                this.filters.to = new Date();
            } else {
                this.filters.from = this.addDaysToDate(-1);
                this.filters.to = this.addDaysToDate(-1);
            }
            this.initList(1);
        },
    },
    mounted() {
        // this.readQuery()
        this.initList(1);
    },
};
</script>
